import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/libs/auth'

import config from './cfg'

Vue.use(Router)

const router = new Router({
    mode: 'hash',
    base: config.prefix,
    routes: [
        {
            path: '*',
            component: () => import('./views/error-page'),
        },
        {
            path: '/',
            name: 'login',
            component: () => import('./views/login'),
            meta: {
                requireAuth: false,
                limit: 0,
            }
        },
        {
            path: '/index',
            component: () => import('./views/index'),
            children: [
                {
                    path: 'account',
                    component: () => import('./views/account'),
                    meta: {
                        requireAuth: true,
                        title: '账号',
                        icon: 'person'
                    },
                },
                {
                    path: 'device',
                    component: () => import('./views/device'),
                    meta: {
                        requireAuth: true,
                        title: '设备',
                        icon: 'person'
                    },
                },
            ]
        },
        {
            path: '/info',
            name: 'info',
            component: () => import('./views/info'),
            meta: {requireAuth: true}
        },
        {
            path: '/error/:code',
            component: () => import('./views/error-page'),
            props: true,
            meta: {
                requireAuth: false,
            }
        },
    ]
})

router.beforeEach((to, from, next) => {
    const role = auth.getRole() || 0;
    if (!auth.userLogin() && to.meta && to.meta.requireAuth) {
        next({
            path: '/',
            query: {redirect: to.fullPath}
        })
    } else if (to.meta && role < to.meta.role) {
        next({
            path: '/error/403'
        })
    } else {
        next()
    }
})


export default router;
