<template>
    <div class="top-menu">
        <el-menu :default-active="$route.path" mode="horizontal" @select="menuSelect">
            <el-menu-item v-for="(nav, index) in navs" :index="nav.path" :key="index">{{ nav.title }}</el-menu-item>
        </el-menu>
    </div>
</template>

<script>
// import { getLimit } from '@/libs/auth'
export default {
    computed: {
        navs() {
            return this.$router.options.routes[2].children.filter(d => d.meta.title).map(nav => {
                return {
                    path: '/index/' + nav.path,
                    title: nav.meta.title,
                }
            })
        }
    },
    methods: {
        menuSelect(index) {
            if(this.$route.path !== index) {
                this.$router.push(index)
            }
        }
    },
}
</script>
