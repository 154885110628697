import axios from 'axios';
import auth from '@/libs/auth';
import config from "@/cfg";

const NETWORK_ERROR = '网络连接错误.';
const baseUrl = `${config.prefix}api/`;

axios.interceptors.response.use(response => {
    if (response.status === 401) {
        auth.removeCookie();
        window.location.reload();
    }
    return response;
})

axios.interceptors.response.use(response => {
    if (response.status === 200) {
        return response
    }
    if (response.status === 401) {
        auth.removeCookie();
        window.location.reload();
    }
    return response;
}, () => {
})

const instance = axios.create({
    baseURL: baseUrl,
    timeout: 1000,
});

const async = request => {
    return new Promise((resolve, reject) => {
        request.then(({status, data}) => {
            if (status === 200) {
                if (data.data === undefined || data.data === null) {
                    data.data = []
                }
                resolve(data)
            } else {
                reject(false)
            }
        })
            .catch(err => {
                reject(NETWORK_ERROR + err)
            })
    })
}

export const api = {
    accounts: {
        login (params) {
            return async(instance.get('accounts/login', {params}))
        },
        auth () {
            return async(instance.get('accounts/auth'))
        },
        info (id) {
            return async(instance.get(`accounts/${id}`))
        },
        list () {
            return async(instance.get('accounts'))
        },
        add (data) {
            return async(instance.post('accounts', data))
        },
        exist (params) {
            return async(instance.get('accounts/exist', {params}))
        },
        update (id, data) {
            return async(instance.put(`accounts/${id}`, data))
        },
        devices (id) {
            return async(instance.get(`accounts/${id}/devices`))
        },
        children (id) {
            return async(instance.get(`accounts/${id}/children`))
        },
        delete (id) {
            return async(instance.delete(`accounts/${id}`))
        },
    },
    devices: {
        list () {
            return async(instance.get('devices'))
        },
        add (data) {
            return async(instance.post('devices', data))
        },
        exist (params) {
            return async(instance.get('devices/exist', {params}))
        },
        update (id, data) {
            return async(instance.put(`devices/${id}`, data))
        },
        field (id, params) {
            return async(instance.patch(`devices/${id}`, null,{params}))
        },
        delete (id) {
            return async(instance.delete(`devices/${id}`))
        },
    },
    logs: {
        list (params) {
            return async(instance.get('logs',{params}))
        },
        'delete' (id) {
            return async(instance.delete(`logs/${id}`))
        }
    },
    info () {
        return async(instance.get('system'))
    },
}
