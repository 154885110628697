<template>
  <div class="account">
    <div class="actions" title="添加账号" v-if="canWrite">
      <el-button @click="showAdd" type="primary" icon="el-icon-folder-add" size="small">添加账号</el-button>
    </div>
    <el-table class="full-width" :data="rows" v-loading="loading" stripe
              row-key="id" border
              lazy :load="loadChildren"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column type="index" width="50" fixed="left"/>
      <el-table-column v-for="(column, index) in columns"
                       :show-overflow-tooltip="true"
                       :key="index"
                       :sortable="column.sortable"
                       :prop="column.name"
                       :label="column.title"
                       :width="column.width"
                       :fixed="column.fixed"
                       :min-width="column.minWidth">
        <template slot-scope="scope">
          <p v-if="column.name === 'role'">
            <el-tag v-if="scope.row.role === 1">超级管理员</el-tag>
            <el-tag type="success" v-else-if="scope.row.role === 2">管理员</el-tag>
            <el-tag type="info" v-else>普通账号</el-tag>
          </p>
          <p v-else-if="column.name === 'created_at'">{{ scope.row[column.name]|format }}</p>
          <p v-else-if="column.name === 'updated_at'">{{ scope.row[column.name]|format }}</p>
          <el-tooltip v-else-if="column.name === 'pwd'" class="item" effect="dark" :content="scope.row[column.name]"
                      placement="top">
            <el-button type="text"><i class="el-icon-view el-icon--right"></i></el-button>
          </el-tooltip>
          <span v-else>{{ scope.row[column.name] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="120" width="150" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" @click="getDevices(scope.row)" type="primary" circle title="绑定的设备"
                     icon="el-icon-monitor"/>
          <el-button size="mini" @click="updateAccount(scope.row)" type="warning" circle title="编辑"
                     icon="el-icon-edit"/>
          <el-button size="mini" type="danger" circle v-if="canWrite" title="删除"
                     icon="el-icon-delete" @click="deleteAccount(scope.row)"/>
        </template>
      </el-table-column>
    </el-table>
    <div class="paging padding">
      <el-pagination background layout="prev, pager, next" :total="accounts.length" :current-page.sync="currentPage"
                     :hide-on-single-page="true"/>
    </div>
    <el-dialog :title="isEdit ? '编辑账号' : '添加账号'" :visible.sync="showDialog" width="450px"
               :modal-append-to-body="false" @closed="cancel">
      <el-form :model="account" :rules="rules" ref="form" label-width="100px">
        <el-form-item prop="name" label="账号名">
          <el-input v-model="account.name" placeholder="请输入账号名"/>
        </el-form-item>
        <el-form-item prop="pwd" label="密码">
          <el-input v-model="account.pwd" type="password" placeholder="请输入密码" show-password/>
        </el-form-item>
        <el-form-item prop="desc" label="备注">
          <el-input v-model="account.desc" type="textarea" placeholder="请输入备注" rows="3"/>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button type="primary" size="small" @click="submit('form')">确定</el-button>
      </div>
    </el-dialog>
    <el-drawer
        :title="'账号: '+whoDrawer+' 设备绑定列表'"
        :visible.sync="showDrawer"
        direction="ltr"
        size="70%">
      <el-table :data="devices" border stripe>
        <el-table-column type="expand" fixed="left">
          <template slot-scope="props">
            <el-descriptions :title="props.row.name" style="padding: 5px 20px" size="small">
              <el-descriptions-item label="密码">{{ props.row.pwd }}</el-descriptions-item>
              <el-descriptions-item label="添加时间">{{ props.row['created_at']|format }}</el-descriptions-item>
              <el-descriptions-item label="客户端id">{{ props.row['remote_id'] }}</el-descriptions-item>
              <el-descriptions-item label="备注">{{ props.row.remark }}</el-descriptions-item>
            </el-descriptions>
          </template>
        </el-table-column>
        <el-table-column type="index" label="索引" width="60" fixed="left"></el-table-column>
        <el-table-column property="id" label="设备id" min-width="160" fixed="left"></el-table-column>
        <el-table-column property="name" label="设备名" min-width="80"></el-table-column>
        <el-table-column property="pwd" label="设备密码" min-width="80">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.pwd" placement="top">
              <el-button type="text"><i class="el-icon-view el-icon--right"></i></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column property="status" label="状态" min-width="80">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status === 1">在线</el-tag>
            <el-tag type="danger" v-else>离线</el-tag>
          </template>
        </el-table-column>
        <el-table-column property="type" label="设备类型" min-width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type === 1">便携式</el-tag>
            <el-tag type="success" v-else-if="scope.row.type === 2">在线式</el-tag>
          </template>
        </el-table-column>
        <el-table-column property="updated_at" label="最新推送时间" width="200">
          <template slot-scope="scope">{{ scope['push_at']|format }}</template>
        </el-table-column>
        <el-table-column label="操作" min-width="80" fixed="right">
          <template slot-scope="scope">
            <el-button round size="mini" type="danger" v-if="canWrite" title="解绑" @click="unbind(scope.row)"
                       circle icon="el-icon-link"/>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
import {api} from "@/libs/api";
import {CanWrite, ConstRole, IsAdmin} from "@/libs/const";
import moment from "moment";
import auth from "@/libs/auth";

const Account = {}

export default {
  data: () => ({
    columns: [
      {name: 'name', title: '账号名', sortable: true, width: 200, fixed: 'left'},
      {name: 'pwd', title: '密码', width: 80},
      {name: 'role', title: '角色', sortable: true, width: 120,},
      {name: 'desc', title: '备注', sortable: true, minWidth: 200},
      {name: 'created_at', title: '创建时间', sortable: true, width: 180},
      {name: 'updated_at', title: '更新时间', sortable: true, width: 180},
    ],
    accounts: [],
    devices: [],
    currentPage: 1,
    account: {
      name: '',
      pwd: '',
      role: 1,
    },
    rules: {
      name: [
        {required: true, message: '账号名不能为空', trigger: 'blur'},
        {
          validator: async (rule, value, callback) => {
            if (value === Account.name){
              callback()
              return
            }
            let res = await api.accounts.exist({key: 'name', value})
            if (res.status && res.data) {
              callback(new Error(`账号名: ${value}已存在请换一个`))
              return
            }
            callback()
          }, trigger: 'blur'
        }
      ],
      pwd: [{required: true, message: '密码不能为空', trigger: 'blur'}],
    },
    loading: false,
    showDialog: false,
    showDrawer: false,
    whoDrawer: false,
    isEdit: false,
    canWrite: CanWrite()
  }),
  filters: {
    format (v) {
      return moment(v).format('L LTS')
    }
  },
  mounted () {
    this.list()
  },
  methods: {
    async list () {
      this.loading = true;
      let res = await api.accounts.list()
      if (res.status) {
        this.accounts = res.data.map(v => {
          return {hasChildren: true, ...v}
        })
      } else {
        this.$message.error('获取账号失败, ' + res.msg);
      }
      this.loading = false;
    },
    cancel () {
      this.showDialog = false;
      this.reset();
    },
    reset () {
      this.$refs.form.resetFields();
      this.account = {
        name: '',
        pwd: '',
        desc: ''
      }
    },
    showAdd () {
      this.isEdit = false;
      this.showDialog = true;
    },
    updateAccount (row) {
      this.isEdit = true;
      this.account = {...row};
      Account.name = row.name
      this.showDialog = true;
    },
    submit () {
      this.$refs.form.validate(async valid => {
        if (!valid)
          return false;
        if (IsAdmin) {
          this.account.role = ConstRole.Manager
        } else {
          this.account.pid = auth.getAccountName()
          this.account.role = ConstRole.Account
        }
        let res
        if (this.isEdit) {
          res = await api.accounts.update(this.account.id, this.account)
        } else {
          res = await api.accounts.add(this.account)
        }
        if (res.status) {
          this.$message.success('操作成功')
          await this.list()
        } else {
          this.$message.error(res.msg)
        }
        this.showDialog = false
      })
    },
    async deleteAccount ({id}) {
      let ok = await this.$confirm('此操作将永久删除该账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (ok) {
        let res = await api.accounts.devices(id)
        if (res.status && res.data.length > 0) {
          this.$message.error('当前账号有绑定的设备,先解绑后在删除!')
          return
        }
        res = await api.accounts.delete(id)
        if (res.status) {
          this.accounts = this.accounts.filter(v => v.id !== id)
          this.$message.success('删除账号成功')
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    async getDevices ({name, id}) {
      let res = await api.accounts.devices(id)
      if (res.status) {
        this.devices = res.data
        this.showDrawer = true
        this.whoDrawer = name
      }
    },
    async loadChildren (tree, treeNode, resolve) {
      let res = await api.accounts.children(tree.id)
      if (res.status) {
        resolve(res.data)
      }
    },
    async unbind ({id, account_id}) {
      let res = await api.accounts.info(account_id)
      if (res.status) {
        let ok = await this.$confirm(`是否将该设备与账号 ${res.data.name} 解绑, 是否继续?`, '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(() => {
        })
        if (ok) {
          let res = await api.devices.field(id, {'key[account_id]': 0})
          if (res.status) {
            await this.getDevices({name: res.data.name, id: account_id})
            this.$message.success('解绑成功')
          } else {
            this.$message.error(res.msg)
          }
        }
      } else {
        this.$message.error('未找到当前设备绑定的账号')
      }
    },
  },
  computed: {
    rows () {
      let limit = (this.currentPage - 1) * 10;
      return this.accounts.slice(limit, limit + 10);
    }
  }
}
</script>
