import auth from "@/libs/auth";

export const ConstRole = {
    Admin: 1,
    Manager: 2,
    Account: 3,
}
export const IsAdmin = () => {
    return auth.getRole() === ConstRole.Admin
}
export const CanWrite = () => {
    let role = auth.getRole()
    return role === ConstRole.Admin || role === ConstRole.Manager
}
