<template>
  <div class="index">
    <header>
      <div class="logo">
        <img src="@/assets/logo.png" alt="logo">
      </div>
      <div class="account-info">
        <el-tooltip :content="info.compile|format|append('前端编译时间: ')" placement="bottom-start">
          <div>v{{ info.compile|format('YYYYMMDDHHmmss') }}</div>
        </el-tooltip>
        <el-tooltip :content="'后台版本: '+info.version.tag+',日志:'+info.version.log" placement="bottom-start">
          <div>v{{ info.version.tag }}</div>
        </el-tooltip>
        <el-tooltip :content="'系统运行时间: '+info.start_time" placement="bottom-start">
          <div>运行于{{ info.running_time }}</div>
        </el-tooltip>
      </div>
      <div class="account-title">
        <div class="account-title-name">账号系统</div>
        <div class="account-title-now">{{ info.now }}</div>
      </div>
      <top-menu/>
      <el-dropdown @command="handleDrop">
                <span class="el-dropdown-link">
                    {{ accountName }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
        <el-dropdown-menu slot="dropdown">
<!--          <el-dropdown-item command="1">个人信息</el-dropdown-item>-->
          <el-dropdown-item command="0">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </header>
    <div class="layout">
      <transition name="translate">
        <router-view class="child-view padding"/>
      </transition>
    </div>
  </div>
</template>

<script>
import TopMenu from '@/components/topmenu'
import auth from '@/libs/auth'
import {api} from '@/libs/api'
import {mapState} from 'vuex'
import moment from "moment";
import watermark from 'watermark-package'

moment.locale('zh-cn');

export default {
  data () {
    return {
      info: {
        version: {
          tag: 'loading...',
          log: 'loading...'
        },
        compile: Number(window['__compile__']),
        running_time: 'loading...',
        start_time: '2020-12-12 12:12:12',
        now: ''
      }
    }
  },
  components: {TopMenu},
  methods: {
    handleDrop (cmd) {
      [
        () => {
          auth.removeCookie();
          this.$router.push('/');
        },
        () => {
          this.$router.push('/')
        }
      ][cmd].call(this)
    },
    formatTime (time, format = 'YYYY-MM-DD HH:mm:ss') {
      return moment(time).format(format)
    },
  },
  filters: {
    append (v, str) {
      return str + v
    },
    format (time, format = 'YYYY-MM-DD HH:mm:ss') {
      return moment(time).format(format)
    },
  },
  async created () {
    watermark.setWaterMark(
        {
          w_texts: ['account.furthersense.com', this.formatTime(this.info.compile), 'author: zing'],
          w_options: {
            w_width: 280,
            w_height: 200,
            w_top: '0px',
            w_left: '0px',
            w_rotateDeg: 20,
            w_font: '0.8rem Vedana',
            w_color: '#555',
            w_opacity: '0.05',
            w_zIndex: '100000',
          }
        }
    );
    let res = await api.info()
    if (res.status) {
      this.info.version = res.data.version
      this.info.start_time = res.data.start_time
      setInterval(() => {
        this.info.running_time = moment(res.data.start_time).fromNow();
        this.info.now = moment().format('L LTS')
      }, 1000)
    }
  },
  computed: mapState(['accountName']),
}
</script>

<style scoped>
.account-title {
  position: absolute;
  min-width: 80px;
  left: 80px;
  color: gray;
}

.account-title-name {
  font-weight: bolder;
  font-size: 20px;
}

.account-title-now {
  font-size: 10px;
  font-weight: lighter;
}

.account-info {
  position: absolute;
  min-width: 80px;
  right: 90px;
  font-size: 10px;
  color: #bebebe;
}

.index {
  display: flex;
  flex-direction: column;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: #fff;
}

.logo {
  width: 50px;
}

.layout {
  position: relative;
  height: 100%;
  overflow: hidden;
  flex-shrink: 1;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.translate-enter-active, .translate-leave-active {
  transition: all .4s ease;
}

.translate-enter, .translate-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
