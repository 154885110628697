<template>
  <div class="login" @keydown="keydown">
    <div class="layout">
      <div class="header">账号系统</div>
      <el-form :model="account" :rules="rules" ref="accounts" @submit.native.prevent>
        <el-form-item prop="name">
          <el-input v-model="account.name" placeholder="请输入账号名" :disabled="loading">
            <template slot="prepend">
              <i class="el-icon-user"></i>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="pwd" style="margin-bottom:20px">
          <el-input v-model="account.pwd" type="password" placeholder="请输入密码" :disabled="loading" show-password>
            <template slot="prepend">
              <i class="el-icon-lock"></i>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-button class="full-width" @click="reset" :disabled="loading">重置</el-button>
            </el-col>
            <el-col :span="12">
              <el-button class="full-width" type="primary" native-type="submit" :loading="loading" @click="submit">
                登录
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<style scoped>
.login .layout {
  position: absolute;
  background: #fff;
  width: 500px;
  padding: 50px 100px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  box-shadow: 5px 5px 100px 0 rgba(10, 10, 10, .2);
}

.login .layout .header {
  font-size: 25px;
  margin-bottom: 25px;
  text-align: center;
}
</style>

<script>
import {api} from "@/libs/api";
import auth from "@/libs/auth";

export default {
  data: () => ({
    account: {
      name: '',
      pwd: ''
    },
    rules: {
      name: [
        {required: true, message: '账号名不能为空', trigger: 'blur'}
      ],
      pwd: [
        {required: true, message: '密码不能为空', trigger: 'blur'}
      ],
    },
    loading: false,
  }),
  methods: {
    keydown (e) {
      // Enter
      if (e.keyCode === 13) {
        e.preventDefault()
        return this.submit()
      }
    },
    submit () {
      this.$refs.accounts.validate(async valid => {
        if (!valid) {
          return false;
        }
        let res = await api.accounts.login(this.account)
        if (res.status) {
          let {token, type, expire} = res.data
          auth.setToken(token)
          let {data: info, status, msg, pid} = await api.accounts.auth()
          if (status) {
            let {id, name, role} = info
            auth.setCookie({account: {id, pid, name, role}, token, type, expire})
            this.$store.commit('AccountName', this.account.name);
            this.$message.success('登录成功')
          } else {
            this.$message.error(msg)
          }
          await this.$router.push('index/account');
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset () {
      this.$refs.accounts.resetFields();
    }
  },
  created () {
    let role = auth.getRole();
    if (role === 1) {
      this.$router.push('index/account');
    }
  },
}
</script>

