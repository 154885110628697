<template>
  <div class="error-page bg-grey" @mousemove="handleEvent">
    <div class="layout">
      <h1 class="text" :style="{ textShadow }">{{ code }}</h1>
      <p>
        <span>{{ errMsg }}...</span>
        <a class="link" href="javascript:" @click="$router.back()">返回上一页</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    code: {
      type: String,
      default: '404',
    }
  },
  data: () => ({
    x: 0,
    y: 0,
  }),
  methods: {
    handleEvent(ev) {
      this.x = ev.clientX;
      this.y = ev.clientY;
    }
  },
  computed: {
    textShadow() {
      let center = {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      };
      return `${(center.x - this.x) * 20 / center.x}px ${(center.y - this.y) * 20 / center.y}px 8px #999`
    },
    errMsg() {
      if (this.code === '403') {
        return '访问被阻止，无权限访问该页面'
      }
      if (this.code === '404') {
        return '无法找到页面'
      }
      return 'error'
    }
  },
}
</script>

<style scoped>
.layout {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.text {
  font-size: 150px;
  color: #da251d;
}

.layout p {
  font-size: 14px;
  text-align: center;
}
</style>
