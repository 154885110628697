<template>
  <div class="device">
    <div class="actions" title="添加设备" v-if="canWrite">
      <el-button @click="showAdd" type="primary" icon="el-icon-folder-add" size="small">添加设备</el-button>
    </div>
    <el-table class="full-width" :data="rows" v-loading="loading" stripe
              row-key="id" border>
      <el-table-column type="index" width="50" fixed="left"/>
      <el-table-column v-for="(column, index) in columns"
                       :show-overflow-tooltip="true"
                       :key="index"
                       :sortable="column.sortable"
                       :prop="column.name"
                       :label="column.title"
                       :width="column.width"
                       :fixed="column.fixed"
                       :min-width="column.minWidth">
        <template slot-scope="scope">
          <template v-if="column.name === 'id'">
            <el-tooltip class="item" content="点击复制" placement="right-start">
              <el-link type="primary" class="label"
                       :underline="false"
                       v-clipboard:copy="scope.row.id"
                       v-clipboard:success="onCopy"
                       v-clipboard:error="onError">{{ scope.row.id }}
              </el-link>
            </el-tooltip>
          </template>
          <template v-else-if="column.name === 'type'">
            <el-tag v-if="scope.row.type === 1">便携式</el-tag>
            <el-tag type="success" v-else-if="scope.row.type === 2">在线式</el-tag>
            <el-tag type="info" v-else>非法设备</el-tag>
          </template>
          <template v-else-if="column.name === 'status'">
            <el-tag type="success" v-if="scope.row.status === 1">在线</el-tag>
            <el-tag type="danger" v-else>离线</el-tag>
          </template>
          <template v-else-if="column.name === 'account_id'">
            <el-tag type="danger" v-if="scope.row['account_id'] === 0">未绑定</el-tag>
            <el-tag type="success" v-else>已绑定</el-tag>
          </template>
          <template v-else-if="column.name === 'created_at'">{{ scope.row[column.name]|format }}</template>
          <template v-else-if="column.name === 'push_at'">{{ scope.row[column.name]|format }}</template>
          <el-tooltip v-else-if="column.name === 'pwd'" class="item" effect="dark" :content="scope.row[column.name]"
                      placement="top">
            <el-button type="text"><i class="el-icon-view el-icon--right"></i></el-button>
          </el-tooltip>
          <template v-else>{{ scope.row[column.name] }}</template>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" @click="info(scope.row)" v-if="scope.row['account_id'] !== 0" type="primary"
                     circle title="详情" icon="el-icon-user"/>
          <el-button size="mini" @click="showBind(scope.row)" v-if="scope.row['account_id'] === 0" type="success"
                     circle title="绑定" icon="el-icon-connection"/>
          <el-button size="mini" @click="unbind(scope.row)" v-if="scope.row['account_id'] !== 0" type="warning"
                     circle title="解绑" icon="el-icon-link"/>
          <el-button size="mini" @click="updateDevice(scope.row)" type="info" circle title="编辑"
                     icon="el-icon-edit"/>
          <el-button size="mini" type="danger" circle v-if="canWrite && scope.row['account_id'] === 0" title="删除"
                     icon="el-icon-delete" @click="deleteDevice(scope.row)"/>
        </template>
      </el-table-column>
    </el-table>
    <div class="paging padding">
      <el-pagination background layout="prev, pager, next" :total="devices.length" :current-page.sync="currentPage"
                     :hide-on-single-page="true"/>
    </div>
    <el-dialog :title="isEdit ? '编辑设备' : '添加设备'" :visible.sync="showDialog" width="450px"
               :modal-append-to-body="false" @closed="cancel">
      <el-form :model="device" :rules="rules" ref="form" label-width="100px">
        <el-form-item prop="id" label="设备id">
          <el-input v-model="device.id" placeholder="请输入设备id" trim/>
        </el-form-item>
        <el-form-item prop="name" label="设备名">
          <el-input v-model="device.name" placeholder="请输入设备名"/>
        </el-form-item>
        <el-form-item prop="pwd" label="密码">
          <el-input v-model="device.pwd" type="password" placeholder="请输入密码" show-password/>
        </el-form-item>
        <el-form-item prop="type" label="设备类型">
          <el-radio v-model="device.type" label="1">便携式</el-radio>
          <el-radio v-model="device.type" label="2">在线式</el-radio>
        </el-form-item>
        <el-form-item prop="remark" label="备注">
          <el-input v-model="device.remark" type="textarea" placeholder="请输入备注" rows="3"/>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button type="primary" size="small" @click="submit('form')">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="dialog.bind.title" :visible.sync="dialog.bind.show"
               :modal-append-to-body="false">
      <el-table :data="dialog.bind.data" border height="500" v-loading="dialog.bind.loading">
        <el-table-column prop="id" label="id" width="60"/>
        <el-table-column prop="name" label="姓名" width="180"/>
        <el-table-column prop="desc" label="描述"/>
        <el-table-column label="操作" width="80" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="bind(scope.row)" icon="el-icon-connection" type="primary" circle title="绑定"/>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-drawer :title="dialog.info.title" :visible.sync="dialog.info.show" :append-to-body="true" direction="ltr"
               size="60%">
      <el-descriptions title="设备信息">
        <el-descriptions-item label="设备id">{{ dialog.info.device.id }}</el-descriptions-item>
        <el-descriptions-item label="设备名">{{ dialog.info.device.name }}</el-descriptions-item>
        <el-descriptions-item label="设备密码">{{ dialog.info.device.pwd }}</el-descriptions-item>
        <el-descriptions-item label="推送时间">{{ dialog.info.device['push_at']|format }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{ dialog.info.device['created_at']|format }}</el-descriptions-item>
        <el-descriptions-item label="设备备注">{{ dialog.info.device.remark }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="绑定账号信息">
        <el-descriptions-item label="用户名">{{ dialog.info.account.name }}</el-descriptions-item>
        <el-descriptions-item label="角色">
          <el-tag size="small" v-if="dialog.info.account.role === 1">超级管理员</el-tag>
          <el-tag size="small" type="success" v-else-if="dialog.info.account.role === 2">管理员</el-tag>
          <el-tag size="small" type="info" v-else>普通账号</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <h4>最新推送记录</h4>
      <el-table :data="logs" style="width: 100%" stripe height="600">
        <el-table-column type="index" width="60"></el-table-column>
        <el-table-column prop="content" label="推送内容" min-width="280"></el-table-column>
        <el-table-column prop="at" label="推送时间" width="160">u
          <template slot-scope="scope">
            <span> {{ scope.row.at|format }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
import {api} from "@/libs/api";
import {CanWrite} from "@/libs/const";
import moment from "moment";

const device = {}
export default {
  data: () => ({
    columns: [
      {name: 'id', title: '设备id', sortable: true, width: 150, minWidth: 140, fixed: 'left'},
      {name: 'name', title: '设备名', sortable: true, width: 150, minWidth: 140, fixed: 'left'},
      {name: 'pwd', title: '密码', width: 60,},
      {name: 'account_id', title: '绑定', sortable: true, width: 100},
      {name: 'status', title: '状态', sortable: true, width: 100},
      {name: 'type', title: '类型', sortable: true, width: 100,},
      {name: 'remark', title: '备注', sortable: true, minWidth: 160},
      {name: 'push_at', title: '最新推送时间', sortable: true, width: 160},
      {name: 'created_at', title: '创建时间', sortable: true, width: 160},
    ],
    devices: [],
    logs: [],
    currentPage: 1,
    device: {
      id: '',
      name: '',
      pwd: '',
      type: '1',
      remark: ''
    },
    rules: {
      id: [{required: true, message: '设备id不能为空', trigger: 'blur'},
        {
          validator: async (rule, value, callback) => {
            if (device.id === value) {
              callback()
              return
            }
            let res = await api.devices.exist({key: 'id', value})
            if (res.status && res.data) {
              callback(new Error(`设备id: ${value} 已存在请换一个`))
              return
            }
            callback()
          }, trigger: 'blur'
        }],
      name: [
        {required: true, message: '设备名不能为空', trigger: 'blur'},
      ],
      type: [
        {required: true, message: '设备类型不能为空', trigger: 'blur'},
      ],
      remark: [
        {required: true, message: '备注不能为空', trigger: 'blur'},
      ],
    },
    loading: false,
    showDialog: false,
    dialog: {
      bind: {
        title: '',
        device: {},
        show: false,
        loading: false,
        data: []
      },
      info: {
        title: '',
        show: false,
        loading: false,
        account: {},
        device: {}
      }
    },
    isEdit: false,
    canWrite: CanWrite()
  }),
  filters: {
    format (v) {
      return moment(v).format('L LTS')
    }
  },
  mounted () {
    this.list()
  },
  methods: {
    onCopy (e) {
      this.$message.success(`复制成功: ${e.text}`)
    },
    onError (e) {
      this.$message.error(`复制失败: ${e}`)
    },
    async list () {
      this.loading = true;
      let res = await api.devices.list()
      if (res.status) {
        this.devices = res.data
      } else {
        this.$message.error('获取设备失败, ' + res.msg);
      }
      this.loading = false;
    },
    cancel () {
      this.showDialog = false;
      this.reset();
    },
    reset () {
      this.$refs.form.resetFields();
      this.device = {
        id: '',
        name: '',
        pwd: '',
        type: '1',
        remark: ''
      }
    },
    showAdd () {
      this.isEdit = false;
      this.showDialog = true;
    },
    async info (row) {
      let loading = this.$loading({})
      let res = await api.accounts.info(row['account_id'])
      if (res.status) {
        this.dialog.info = {
          ...this.dialog.info,
          title: `设备 ${row.name === '' ? row.id : row.name} 详情`,
          show: true,
          loading: true,
          device: row,
          account: res.data
        }
      } else {
        this.$message.error(res.msg)
      }
      res = await api.logs.list({
        device_id: row.id,
        type: 1,
      })
      if (res.status) {
        this.logs = res.data
      }
      loading.close()
    },
    async showBind (row) {
      this.dialog.bind = {
        ...this.dialog.bind,
        title: `为设备 ${row.name} 绑定账号`,
        device: row,
        show: true,
        loading: true
      }
      let res = await api.accounts.list()
      if (res.status) {
        this.dialog.bind = {
          ...this.dialog.bind,
          data: res.data,
          loading: false
        }
      } else {
        this.$message.error(res.msg)
        this.dialog.bind.loading = false
      }
    },
    async bind (row) {
      let device = this.dialog.bind.device
      let loading = this.$loading({});
      let res = await api.devices.field(device.id, {'key[account_id]': row.id})
      if (res.status) {
        await this.list()
        this.dialog.bind.show = false
        this.$message.success("绑定成功")
      } else {
        this.$message.error(`绑定失败: ${res.msg}`)
      }
      loading.close()
    },
    async unbind ({id, account_id}) {
      let res = await api.accounts.info(account_id)
      if (res.status) {
        let ok = await this.$confirm(`是否将该设备与账号 ${res.data.name} 解绑, 是否继续?`, '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(() => {
        })
        if (ok) {
          let res = await api.devices.field(id, {'key[account_id]': 0})
          if (res.status) {
            await this.list()
            this.$message.success('解绑成功')
          } else {
            this.$message.error(res.msg)
          }
        }
      } else {
        this.$message.error('未找到当前设备绑定的账号')
      }
    },
    updateDevice (row) {
      this.isEdit = true;
      device.id = row.id
      this.device = {...row, type: String(row.type)};
      this.showDialog = true;
    },
    submit () {
      this.$refs.form.validate(async valid => {
        if (!valid)
          return false;
        let res
        this.device.type = Number(this.device.type)
        if (this.isEdit) {
          res = await api.devices.update(this.device.id, this.device)
        } else {
          res = await api.devices.add(this.device)
        }
        if (res.status) {
          this.$message.success('操作成功')
          await this.list()
        } else {
          this.$message.error(res.msg)
        }
        this.showDialog = false
      })
    },
    async deleteDevice ({id}) {
      let ok = await this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => {
      })
      if (ok) {
        let res = await api.devices.delete(id)
        if (res.status) {
          this.devices = this.devices.filter(v => v.id !== id)
          this.$message.success('删除成功')
        } else {
          this.$message.error(res.msg)
        }
      }
    },
  },
  computed: {
    rows () {
      let limit = (this.currentPage - 1) * 10;
      return this.devices.slice(limit, limit + 10);
    }
  }
}
</script>
