import Cookies from 'js-cookie'

const cookieKey = 'further-sense-account-platform';

const getCookieParams = () => {
    let cookie = Cookies.get(cookieKey);
    if (!cookie) return ''
    else return JSON.parse(cookie)
}

const getAccount = () => {
    let cookie = getCookieParams();
    return cookie.account || {};
}

export default {
    userLogin: () => {
        let cookie = getCookieParams();
        return cookie !== '';
    },
    getToken: () => {
        let cookie = getCookieParams();
        return cookie.token;
    },
    getTokenType: () => {
        let cookie = getCookieParams();
        return cookie.type;
    },

    getAccountName: () => {
        return getAccount().name
    },
    getRole: () => {
        return getAccount().role
    },
    setCookie: value => {
        Cookies.set(cookieKey, JSON.stringify(value), {expires: new Date(value.expire)});
    },
    removeCookie: () => {
        Cookies.remove(cookieKey);
    },

    setToken: (token) => Cookies.set('jwt', token)
}
