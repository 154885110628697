import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/libs/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accountName: auth.getAccountName(),
    role: auth.getRole(),
  },
  mutations: {
    AccountName(state, name) {
      state.accountName = name;
    },
    setRole(state, role) {
      state.role = role;
    }
  },
  actions: {}
})
