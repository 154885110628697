<template>
<h1>todo...</h1>
</template>

<script>
export default {
  name: "info"
}
</script>

<style scoped>

</style>
